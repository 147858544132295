<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { i18n } from '@/plugins/i18n';
import { useDashboardStore, useSessionStore } from 'src/store';
import { reformatString } from 'src/utils/stringUtils';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import Box from 'src/components/BoxContainer.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import DisclaimerModal from 'src/components/DisclaimerModal.vue';

const sessionStore = useSessionStore();
const router = useRouter();
const dashboardStore = useDashboardStore()

const patientFirstName = computed(() => {
  const { user } = sessionStore;
  return user ? user.firstName : '';
});

const introContent = computed(() => {
  return reformatString(i18n.global.t('checkin_welcome_description').toString());
});

const isRatingRequired = computed(() => {
  return dashboardStore.patient?.interruptData.interrupts.find(x => x.type == "Pain_Check_In") != undefined;
});

function onBackClick() {
  router.push({ name: 'Dashboard.Home' });
}

function onNextClick() {
  const tickSignature = dashboardStore.patient?.painAndOpioidData?.todoTickSignature?.toString() || '';
  const surveyId = dashboardStore.patient?.painAndOpioidData?.questionnaireId?.toString() || '';
  router.push({ name: 'Survey', params: { surveyId, tickSignature } });
}
</script>

<template>
  <q-page class="pain-rating" padding>
    <MultiViewLayout :box-bkgd-color="$zb.enums.BoxBackgroundColorEnum.linearGradient"
                     :box-brdr-radius="$zb.enums.BoxBorderRadiusEnum.default"
                     :box-padding="$zb.enums.BoxPaddingEnum.none"
                     :onBackClick="onBackClick"
                     show-footer>
      
      <!-- Section Title -->
      <template #title>{{ $t('gen_survey') }}</template>
  
      <!-- Heading Text -->
      <template #heading>{{ $t('checkin_welcome_title') }}</template>

      <!-- View Unique Content -->
      <template #default>
        
        <!-- Content -->
        <div class="row bs-gutter pain-rating__content">
          <div class="col text-white text-center">
          
            <!-- Avatar -->
            <div class="pain-rating__avatar">
              <div class="pain-rating__avatar-ring bg-primitives-foundation-50">
                <div class="pain-rating__avatar-ring bg-content-assets-tr-grd-2">
                  <div class="pain-rating__avatar-container">
                    <q-icon name="fas fa-user-md"
                            size="83px" />
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Name -->
            <h1 class="text-white pain-rating__name">{{ $t('vue3_checkin_welcome_name_greeting', [patientFirstName]) }}</h1>
            
            <!-- Instructions -->
            <p v-html="introContent" class="pain-rating__inst" />
            
            <!-- Disclaimer Modal -->
            <DisclaimerModal :policyType="$zb.enums.UserPolicyType.UserPolicyCheckInDisclaimer"
                           titleKey="checkin_disclaimer_title" />
          </div>
        </div>
      </template>
      
      <template #footer>
        <Box :box-drop-shadow="$zb.enums.BoxDropShadowEnum.lg"
             :box-padding="$zb.enums.BoxPaddingEnum.p20"
             :box-width="$zb.enums.BoxWidthEnum.lg"
             class="col-auto">

          <!-- Progress Bar -->
          <ProgressBar @on-back-click="$router.push({ name: 'Dashboard.Home' })"
                       @on-next-click="onNextClick"
                       :back-enabled="!isRatingRequired"
                       :next-enabled="true"
                       :progress="25" />
        </Box>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<style lang="scss" scoped>
@use "sass:math";

.pain-rating { // <main>
  
  // Content
  &__content {
    
    > [class*="col"] {
      padding-block: 82px 150px;
    }
  }
  
  // Avatar
  &__avatar {
    margin-block-end: 47px;
    
    div {
      border-radius: 50%;
      
      > & {
        margin-inline: auto;
      }
    }
    
    &-ring { // <div>
      display: inline-block;
      border-style: solid;
      border-color: transparent;
      
      &.bg-primitives-foundation-50 {
        border-width: 6px;
      }
      
      &.bg-content-assets-tr-grd-2 {
        border-width: 10px;
      }
    }
    
    &-container { // <div>
      $pain-rate-avatar-cont-size: 160px;
      $pain-rate-avatar-cont-brdr-w: 8px;
      
      width: $pain-rate-avatar-cont-size;
      height: $pain-rate-avatar-cont-size;
      background-color: $secondary;
      line-height: ($pain-rate-avatar-cont-size - ($pain-rate-avatar-cont-brdr-w *2));
      border: $pain-rate-avatar-cont-brdr-w solid $white;
    }
  }
  
  &__name { // <p>
    margin-block-end: 33px;
  }
  
  &__inst { // <p>
    $pain-rate-inst-font-size: map-get($font-sizes, '22');
    
    font-size: $pain-rate-inst-font-size;
    line-height: math.div(map-get($line-heights, '26'), $pain-rate-inst-font-size);
  }

  @media (min-width: 400px) {

    // Content
    &__content {

      > [class*="col"] {
        padding-inline: $bs-grid-gutter-md;
      }
    }
  }

  @media (min-width: 480px) {

    // Content
    &__content {

      > [class*="col"] {
        padding-inline: 50px;
      }
    }
  }

  @media (min-width: 560px) {

    // Content
    &__content {

      > [class*="col"] {
        padding-inline: 75px;
      }
    }
  }

  @media (min-width: 620px) {

    // Content
    &__content {

      > [class*="col"] {
        padding-inline: 115px;
      }
    }
  }
}
</style>