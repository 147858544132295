<template>
  <div></div>
</template>

<script lang="ts" setup>
import { defineProps, onActivated } from 'vue';
import { useRouter } from 'vue-router';
import { useActivationStore } from 'src/store/activationModule';
import { useSessionStore } from '@/store/sessionStore';
import { getUserSession } from 'src/api/endpoints/userWeb';
import { type SessionData } from 'src/types/webContracts';
import { Workflow } from 'src/constants/enums';
import { useEventBus } from '@/utils/eventBus';
import { AuthPolicyEnum } from 'src/constants/authPolicyEnum';
import { useQuasar } from 'quasar';
import { translateStepToRoute } from 'src/utils/interfaceUtils';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getRegistrationNextStep } from 'src/api/endpoints/registrationWeb';

const sessionStore = useSessionStore();
const activationStore = useActivationStore()
const router = useRouter();
const eventBus = useEventBus();
const $q = useQuasar()

const props = defineProps({
  returnPath: String,
  returnRouteName: String,
});

onActivated(async () => {
  $q.loading.show()

  try {
    let response: SessionData;

    if (!sessionStore.user?.policies.includes(AuthPolicyEnum.FullTrustPatient)) {
      response = await getUserSession();

      const previousUserHasCobranding = (sessionStore.cobrandedCssRules !== null || sessionStore.cobrandedImages !== null);
      sessionStore.load(response.user);
      sessionStore.setCobranding(response.imageUrls, response.theme)

      // Adding theme CSS variables/values to the DOM.
      document.head.innerHTML += `<style type="text/css">:root {${response.theme}}</style>`;

      //MM-31085: If the previous user had cobranding and the current user does not, refresh to remove the cobranding.
      if(response.theme === null && previousUserHasCobranding) {
        router.go(0);
      }
    } else {
      response = { user: sessionStore.user!, theme: '' };
    }

    $q.loading.hide()
    eventBus.emit('user-authenticated');
    if (
      sessionStore.user?.policies.includes(AuthPolicyEnum.RegisteredPatientWithout2FA) &&
      //guards against scenario where user completes login but not 2FA, then refresh page to enforce providing creds again
      sessionStore.completedLogin
    ) {
      router.push({
        name: 'ConfirmIdentity',
        query: {
          workflow: Workflow.deviceOrIp.toString(),
          backRoute: 'Login',
          nextRoute: 'SelectCarePlan',
          phoneCountryCode: response.user.phoneCountryCode,
          phoneNumber: response.user.phoneNumber,
          country: response.user.clientCountryCode,
          progress: '100',
        },
      });
    } else if (sessionStore.user?.policies.includes(AuthPolicyEnum.UnregisteredPatientWith2FA)) {
      if (activationStore.isRecoveringUsername) {
        activationStore.setIsRecoveringUsername(false);
        router.push({ name: 'Activate' });
      } else {
        $q.loading.show();
        const step = await handleApiRequest(() => getRegistrationNextStep(), null, $q).finally(() => $q.loading.hide());
        const nextRoute = translateStepToRoute(step?.result as string);
        router.push({ name: nextRoute });
      }
    } else if (props.returnPath) {
      if (props.returnPath === '/Dashboard/Home/true') {
        router.replace({ name: 'Dashboard.Home' })
      } else {
        router.replace({ path: props.returnPath });
      }
    } else if (props.returnRouteName && router.hasRoute(props.returnRouteName)) {
      router.replace({ name: props.returnRouteName });
    } else if (sessionStore.user?.policies.includes(AuthPolicyEnum.FullTrustPatient)) {
      if (!sessionStore.user.selectedEocId) {
        router.replace({ name: 'SelectCarePlan' });
      } else {
        router.replace({ name: 'Interrupts' });
      }
    } else {
      router.replace({ name: 'Welcome' });
    }
  } catch (error) {
    $q.loading.hide()
    router.replace({ name: 'Welcome' });
  }
});
</script>