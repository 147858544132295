<script setup lang="ts">
import {i18n} from '@/plugins/i18n';
import {
  computed,
  defineEmits,
  defineProps,
  type PropType
} from 'vue';
import {type DropdownMenuItem} from '@/types/webContracts';

const $t = i18n.global.t;

const emit = defineEmits(['on-item-click'])

const props = defineProps({
  options: {
    type: Array as PropType<DropdownMenuItem[]>,
    default: () => []
  }
});

const label = computed(() => {
  const text = $t('drop_down_default'),
    active = props.options.find(o => o.active);
  
  return active ? active.label : text;
});

function onItemClick(val: number | string | undefined | null) {
  emit('on-item-click', val);
}
</script>

<template>
  <q-btn-dropdown auto-close
                  content-class="q-btn-dropdown-menu"
                  dropdown-icon="fas fa-angle-down"
                  :label="label"
                  menu-anchor="bottom start"
                  menu-self="top start"
                  no-caps
                  outline
                  :ripple="false"
                  transition-duration="0"
                  unelevated>
    <q-list>
      <template v-for="(opt, i) in props.options"
                :key="i">
        <q-item @click="onItemClick(opt.value)"
                :active="opt.active"
                clickable
                :disable="opt.disabled"
                :href="opt.href ? opt.href : undefined"
                :to="opt.name ? { name: opt.name, params: opt.params } : undefined">
          <q-item-section>{{ opt.label }}</q-item-section>
        </q-item>
        <q-separator v-if="i < options.length - 1" />
      </template>
    </q-list>
  </q-btn-dropdown>
</template>

<style lang="scss" scoped></style>