<script lang="ts" setup>
  import { useQuasar } from 'quasar';
  import { setCommunicationPreferences } from 'src/api/endpoints/userWeb';
  import { handleApiRequest } from 'src/api/handleApiRequest';
  import { useSessionStore } from 'src/store';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import MultiViewLayout from '@/components/layouts/MultiViewLayout.vue';

  const sessionStore = useSessionStore()
  const router = useRouter()
  const $q = useQuasar()

  const props = defineProps({
    userResearch: {
      type: Boolean,
      default: false
    },
    email: {
      type: Boolean,
      default: false
    },
    phone: {
      type: Boolean,
      default: false
    }
  });

  const emailValue: boolean = ref(false),
    phoneValue: boolean = ref(false),
    userResearchValue: boolean = ref(false);

  let splashDialog = ref(false)
  let skipForNowDialog = ref(false)
  let contactMethodRequiredDialog = ref(false)
  let savePreferencesDialog = ref(false)

  onMounted(() => {

    document.querySelectorAll('html')[0].scrollTop = 0;

    sessionStore.setIsHeaderVisible(true);
    splashDialog.value = true;
  })

  const onOkayClicked = async () => {
    // Validation
    if (userResearchValue.value == true && emailValue.value == false && phoneValue.value == false) {
      contactMethodRequiredDialog.value = true;
    }
    else {
      let communicationPreferencesRequest: CommunicationPreferencesRequest = {
        emailEnabled: emailValue.value,
        phoneEnabled: phoneValue.value,
        userResearchEnabled: userResearchValue.value
      };
      // Mark the Communication Preferences interrupt as completed.
      await handleApiRequest(() => setCommunicationPreferences(communicationPreferencesRequest), null, $q);
      await pushToInterrupts();
    }
  }

  const onBackClick = async () => {
    skipForNowDialog.value = true;
  }

  const skipForNowClick = async () => {
    router.back();
  }

  const dontContactMeClick = async () => {
    let communicationPreferencesRequest: CommunicationPreferencesRequest = {
      emailEnabled: false,
      phoneEnabled: false,
      userResearchEnabled: false
    };
    await handleApiRequest(() => setCommunicationPreferences(communicationPreferencesRequest), null, $q);
    router.back();
  }

  const pushToInterrupts = async () => {
    router.push({ name: 'Interrupts', params: { reload: 'false' } });
  }
</script>

<template>
  <q-page id="the-communication-preferences" padding>
    <MultiViewLayout :onBackClick="onBackClick">

      <q-form @submit.prevent="onOkayClicked">

        <!-- Heading -->
        <h2>{{ $t('subscriptions') }}</h2>
        <h5>{{ $t('subscription_source') }}</h5>

        <hr />
        <!-- User Research -->
        <q-toggle v-model="userResearchValue" :label="$t('user_research_opportunities')" />
        <hr />

        <!-- User Research Opt In -->
        <p>{{ $t('communication_preferences_opt_in') }}</p>
        <p><b>{{ $t('user_research_activities') }}</b></p>

        <!-- Contact Methods -->
        <h6>{{ $t('contact_methods') }}</h6>
        <h5>{{ $t('contact_methods_source') }}</h5>

        <hr />
        <!-- Email -->
        <q-toggle v-model="emailValue" :label="$t('email')" />
        <hr />
        <!-- Phone -->
        <q-toggle v-model="phoneValue" :label="$t('phone')" />
        <hr />

        <!-- Save Preferences Button -->
        <zbm-btn :color="$zb.enums.ButtonStdColorEnum.darkTeal" @click="onOkayClicked"
                 :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('save_preferences')" />
      </q-form>

      <!-- Save Preferences Button -->
      <q-dialog v-model="savePreferencesDialog">
        <q-card>
          <q-card-section>
            <div class="text-h6">{{ $t('save_changes') }}</div>
          </q-card-section>

          <q-card-section class="q-pt-none" v-html="$t('save_selections')" />

          <q-card-actions align="right" class="text-primary">
            <q-btn flat :label="$t('yes_save_selections')" v-close-popup @click="savePreferencesClick" />
            <q-btn flat :label="$t('review_selections')" v-close-popup />
            <q-btn flat :label="$t('do_not_save_selections')" v-close-popup @click="dontContactMeClick" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Splash Dialog -->
      <q-dialog v-model="splashDialog">
        <q-card>
          <q-card-section>
            <div class="text-h6">{{ $t('communication') }}</div>
          </q-card-section>

          <q-card-section class="q-pt-none" v-html="$t('communication_description')" />

          <q-card-actions align="right" class="text-primary">
            <q-btn flat :label="$t('gen_okay')" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Skip For Now Dialog -->
      <q-dialog v-model="skipForNowDialog">
        <q-card>
          <q-card-section>
            <div class="text-h6">{{ $t('skip_for_now') }}</div>
          </q-card-section>

          <q-card-section class="q-pt-none" v-html="$t('update_communication_status')" />

          <q-card-actions align="right" class="text-primary">
            <q-btn flat :label="$t('gen_okay')" @click="skipForNowClick" v-close-popup />
            <q-btn flat :label="$t('go_back')" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Contact Method Dialog -->
      <q-dialog v-model="contactMethodRequiredDialog">
        <q-card>
          <q-card-section>
            <div class="text-h6">{{ $t('contact_method_required') }}</div>
          </q-card-section>

          <q-card-section class="q-pt-none" v-html="$t('select_method')" />

          <q-card-actions align="right" class="text-primary">
            <q-btn flat :label="$t('select_contact_method')" v-close-popup />
            <q-btn flat :label="$t('do_not_contact_me')" @click="dontContactMeClick" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>

    </MultiViewLayout>
  </q-page>
</template>
