import { useSettingsStore } from 'src/store/settingsModule';
import { useSessionStore } from '@/store/sessionStore';
import { useEventBus } from 'src/utils/eventBus';
import { useActivationStore } from 'src/store/activationModule';
import { useLocalizationStore } from 'src/store/localizationModule';
import { i18n } from 'src/plugins/i18n'
import { useQuasar } from 'quasar';
import { createRouter, createWebHashHistory } from 'vue-router';
import routes from 'src/router/routes';
import { AuthPolicyEnum } from 'src/constants/authPolicyEnum';

export const registerGuards = (app) => {
  const router = createRouter({ history: createWebHashHistory(), routes });

  router.beforeEach((to: any, from: any, next: any) => {
    const sessionStore = useSessionStore();
    const eventBus = useEventBus();
    const settingsStore = useSettingsStore();
    const activationStore = useActivationStore();
    const localizationStore = useLocalizationStore();
    const $q = useQuasar();

    if (app.config.globalProperties.sysMaint && to.name !== 'SystemMaintenance') {
      next({ name: 'SystemMaintenance' });
    }

    const matchedAnonRoute = to.matched.find((record: any) => record.meta.allowAnonymous)
    if (matchedAnonRoute || sessionStore.isAuthenticated) {
      const nearestWithTitle = to.matched.slice().reverse().find((r: any) => r.meta && r.meta.title)

      if (nearestWithTitle) {
        localizationStore.routeTitleKeys = nearestWithTitle.meta.title;
        localizationStore.siteTitle = settingsStore.appTitle;
      }

      const matchedAuthRoute = to.matched.find((record: any) => sessionStore?.user?.policies.includes(record.meta.policy))
      const authorized = !to.matched.some((record: any) => record.meta.policy) || matchedAuthRoute

      if (authorized) {
        eventBus.emit('route-changed', matchedAuthRoute?.path)

        //behavior: check if this is an activation route and they are not in an activation workflow
        //reason: protect against edge-cases where user's identity is confirmed and they could navigate to account activation routes directly
        if (/^\/Activate/.test(matchedAuthRoute?.path as string) && !activationStore.isActivatingAccount) {
          // Logged in user that is un-registered is also being redirected to complete Activation
          const isUnregisteredPatientWith2FA = sessionStore?.user?.policies?.some(p => p.includes(AuthPolicyEnum.UnregisteredPatientWith2FA));
          if (!isUnregisteredPatientWith2FA) {
            next({ name: 'Login' })
          }
        }

        next()
      } else if (matchedAnonRoute) {
        eventBus.emit('route-changed', matchedAnonRoute.path)
        next()
      }
      else {
        $q.notify({ type: 'error', message: i18n.global.t('session_timed_out').toString() })
        eventBus.emit('route-changed', '/Login')
        next({ name: 'Login' })
      }
    } else {
      const authenticatePath = `/Authenticate/${encodeURIComponent(to.fullPath)}`
      eventBus.emit('route-changed', authenticatePath)
      next({
        path: authenticatePath
      })
    }
  });

  app.use(router)

  return router
}