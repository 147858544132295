import { DropdownItem } from 'src/types/webContracts';
import { getCanaryActivationNextStep } from 'src/api/endpoints/patientWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';

export async function getNextActivationRoute($q: QVueGlobals) {
  $q.loading.show()
  const nextStepResponse = await handleApiRequest(() => getCanaryActivationNextStep(), null, $q).finally(() => {
    $q.loading.hide()
  });

  // error
  const errorMessage = nextStepResponse?.errorMessage || nextStepResponse?.result?.message;
  if (errorMessage && errorMessage.length) {
    // alertsModule.setAlert(createWarningAlert(errorMessage));
    return 'Dashboard.Home';
  }

  const nextStep = nextStepResponse?.result?.nextStep;
  const page = Object.entries(PIQSetupPages).find(([key, value]) => value === nextStep);

  // if next page is not found, use Home page
  const uri = !!page && page.length > 1 ? `PIQSetup.${page[1]}` : 'Dashboard.Home'

  return uri;
}

export async function getPreviousActivationRoute(currentPage: PIQSetupPages) {
  let currentStep = getStepIndexByValues(currentPage);
  return getActivationRoute(--currentStep);
}

// Return the routeName of the page
export async function getActivationRoute(step: number) {
  let page = Object.values(PIQSetupPages)[step - 1];

  // If step doesn't exist default to first step.
  if (!page || page == PIQSetupPages.TermAndConditions)
    page = Object.values(PIQSetupPages)[0];

  return `PIQSetup.${page}`;
}

export function getStepIndexByValues(value: PIQSetupPages): number {
  const indexOfS = Object.values(PIQSetupPages).indexOf(value);
  return indexOfS + 1;
}

export function generateTimeOptions(): DropdownItem[] {
  const result: any[] = [];
  for (let x = 0; x < 24; x++) {
    let suffix = 'AM';
    let hourNumber = x % 12;
    if (hourNumber === 0) {
      hourNumber = 12;
    }

    if (x > 11) {
      suffix = 'PM';
    }

    const combinedValue = `${hourNumber}:00 ${suffix}`;
    const newOption = {
      value: x,
      text: combinedValue
    };
    result.push(newOption);
  }
  return result;
}


// These values match page names from API
export enum PIQSetupPages {
  Start = 'Start',
  Terms = 'CanaryTerms',
  PrivacyPolicy = 'CanaryPrivacy',
  Address = 'CanaryMailingAddress',
  HeightWeight = 'CanaryHeightWeight',
  Bedtime = 'CanaryBedtime',
  Caregiver = 'CanaryCaregiver',
  Complete = 'CanaryActivationComplete',

  // CanaryTermsAndConditions should be treated as CanaryTerms
  // This not a real page but API may return this value when both Terms and Privacy are missing
  TermAndConditions = 'CanaryTermsAndConditions'
}