<script setup lang="ts">
import { getNextActivationRoute, generateTimeOptions } from 'src/services/piqSetupService';
import { getCanarySampleTimezone, updateCanarySampleTimezone } from 'src/api/endpoints/patientWeb';
import { useGeneralPurposeModalStore } from 'src/store';

import { handleApiRequest } from 'src/api/handleApiRequest';
import { PIQSetupPages } from 'src/services/piqSetupService';

import Box from 'src/components/BoxContainer.vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import { createDecisionModal } from 'src/utils/generalPurposeModalFactory';
import { computed, onMounted, ref } from 'vue';
import { i18n } from 'src/plugins/i18n';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const generalPurposeModalStore = useGeneralPurposeModalStore()
const router = useRouter();

const selectedHour = ref<string>('');
const bedTimeHours = ref(generateTimeOptions());

// computed
const dataCaptureMessage = computed(() => {
  const selectedIndex = bedTimeHours.value.findIndex((d) => d.text === selectedHour.value);

  // Data capture time = selected time + 4 hours
  var showHourIndex = (selectedIndex + 4) % bedTimeHours.value.length;

  const showHourText = bedTimeHours.value[showHourIndex]?.text;
  return i18n.global.t('vue3_piq_bedtime_selection_popup', [`<strong>${showHourText}</strong>`]);
});

const formIsValid = computed(() => selectedHour.value !== '')

onMounted(async () => {
  $q.loading.show()
  const response = await handleApiRequest(() => getCanarySampleTimezone(), null, $q).finally(() => {
    $q.loading.hide()
  });

  var bedtime = response?.result?.bedTime;
  selectedHour.value = bedtime ? bedtime : '';
});

// methods
async function onNextClick() {
  if (!formIsValid.value) {
    const info = createDecisionModal('', i18n.global.t('survey_answer_required').toString());
    generalPurposeModalStore.setModal(info);
    return;
  }

  $q.loading.show()
  const response = await handleApiRequest(() =>
    updateCanarySampleTimezone(selectedHour.value), null, $q
  ).finally(() => {
    $q.loading.hide()
  });

  const nextRoute = await getNextActivationRoute($q);
  router.push({
    name: nextRoute,
    params: {
      backRoute: PIQSetupPages.PrivacyPolicy,
    },
  })
    .catch(() => { });
}

async function onBackClick() {
  history.back();
}
</script>

<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick"
                     showFooter>

      <!-- Title -->
      <template #heading>
        {{ $t('piq_activation_timezone') }}
      </template>

      <!-- View Unique Content -->
      <template #default>

        <!-- Instructions -->
        <p>{{ $t('piq_activation_time_selection') }}</p>

        <!-- Bedtime -->
        <div class="row form-row flex-center">

          <!-- Label -->
          <div class="col-auto">
            <label class="mb-none">{{ $t('piq_bedtime_selection') }}</label>
          </div>

          <!-- Options -->
          <div class="col-auto">
            <zbm-select-with-validation v-model="selectedHour"
                                        omit-default-option
                                        optionLabel="text"
                                        :options="bedTimeHours"
                                        optionValue="text"
                                        outlined
                                        :select-width="$zb.enums.FormControlWidthEnum.auto" />
          </div>
        </div>

        <!-- Selection Message -->
        <div v-if="formIsValid"
             class="selection-msg bg-primitives-foundation-10 p-8 radius--md">
          <p v-html="dataCaptureMessage" />
          <p class="text-underline">{{ $t('piq_bedtime_selection_question') }}</p>
          <p>{{ $t('piq_bedtime_selection_upload_details') }}</p>
        </div>
      </template>

      <template #footer>
        <Box class="col-auto" :boxWidth="$zb.enums.BoxWidthEnum.lg" :boxPadding="$zb.enums.BoxPaddingEnum.p20"
             :boxDropShadow="$zb.enums.BoxDropShadowEnum.lg">
          <ProgressBar :progress="60" :nextEnabled="true" @on-back-click="onBackClick" @on-next-click="onNextClick" />
        </Box>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<style lang="scss" scoped></style>