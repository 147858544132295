<template>
  <div />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useActivationStore, useSessionStore } from 'src/store';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { postLogin } from 'src/api/endpoints/loginWeb';
import { useQuasar } from 'quasar';
import { getUserSession } from 'src/api/endpoints/userWeb';
import { AuthPolicyEnum } from 'src/constants/authPolicyEnum';

const router = useRouter();
const sessionModule = useSessionStore();
const activationStore = useActivationStore()
const $q = useQuasar()


onMounted(async () => {
  $q.loading.show()
  const model = {
    username: activationStore.registrationUsername,
    password: activationStore.registrationPassword,
    utcOffset: sessionModule.utcOffset,
    countryCode: activationStore.supportedCountries.selectedCountryCode,
  };

  if (model.username && model.password) {
    await handleApiRequest(() => postLogin(model), null, $q).finally(() => $q.loading.hide);
    activationStore.setResetState();

    const userSession = await getUserSession();
    const isUnregisteredPatientWith2FA = userSession?.user?.policies?.some(p => p.includes(AuthPolicyEnum.UnregisteredPatientWith2FA));
    // If user is still not registered for any reason
    if (isUnregisteredPatientWith2FA) {
      router.replace({ name: 'Welcome' });
    } 

    router.push({ name: 'Authenticate' });
  } else {
    $q.loading.hide
    router.replace({ name: 'Welcome' });
  }
});
</script>