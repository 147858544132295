<template>
  <q-page padding>
    <q-form @submit="onNextClick"
            ref="localForm">
      <MultiViewLayout :onBackClick="onBackClick"
                       showFooter>

        <!-- Title -->
        <template #heading>
          {{ $t('piq_height') }} & {{ $t('piq_weight') }}
        </template>

        <!-- View Unique Content -->
        <template #default>
          
          <!-- Height -->
          <div class="row form-row items-center">
            
            <!-- Label -->
            <div class="col-2">
              <h2>{{ $t('piq_height') }}</h2>
            </div>
            
            <!-- Feet Measurement -->
            <div class="col-auto pr-none">
              <zbm-select-with-validation v-model="selectedHeightFt"
                                          mode="passive"
                                          omit-default-option
                                          optionLabel="text"
                                          :options="heightFt"
                                          optionValue="value"
                                          outlined
                                          :rules="[$zb.validators.required()]"
                                          :selectWidth="$zb.enums.FormElWidthEnum.qFormElAuto" />
            </div>
            
            <!-- Feet Unit -->
            <div class="col-auto pl-8">
              <p>{{ $t('piq_height_ft') }}</p>
            </div>
            
            <!-- Inches Measurement -->
            <div class="col-auto pr-none">
              <zbm-select-with-validation v-model="selectedHeightIn"
                                          mode="passive"
                                          omit-default-option
                                          optionLabel="text"
                                          :options="heightIn"
                                          optionValue="value"
                                          outlined
                                          :rules="[$zb.validators.required()]"
                                          :selectWidth="$zb.enums.FormElWidthEnum.qFormElAuto" />
            </div>

            <!-- Inches Unit -->
            <div class="col-auto pl-8">
              <p>{{ $t('piq_height_in') }}</p>
            </div>
          </div>
          
          <!-- Weight -->
          <div class="row form-row items-center">
            
            <!-- Label -->
            <div class="col-2">
              <h2>{{ $t('piq_weight') }}</h2>
            </div>
            
            <!-- Measurement -->
            <div class="col-auto pr-none">
              <zbm-number-input-with-validation v-model="selectedWeightLbs"
                                                :inputWidth="$zb.enums.FormElWidthEnum.qFormEl100"
                                                :max="2000"
                                                :min="1"
                                                outlined
                                                :rules="[$zb.validators.required()]" />
            </div>
            
            <!-- Unit -->
            <div class="col-auto pl-8">
              <p>{{ $t('piq_weight_lbs') }}</p>
            </div>
          </div>
        </template>

        <template v-slot:footer>
          <Box class="col-auto" :boxWidth="$zb.enums.BoxWidthEnum.lg" :boxPadding="$zb.enums.BoxPaddingEnum.p20"
            :boxDropShadow="$zb.enums.BoxDropShadowEnum.lg">
            <ProgressBar :progress="50" :nextEnabled="isFormValid" @on-back-click="onBackClick"
              @on-next-click="onNextClick" />
          </Box>
        </template>
      </MultiViewLayout>
    </q-form>
  </q-page>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { handleApiRequest } from 'src/api/handleApiRequest';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import { BoxWidthEnum } from 'src/constants/enums';
import Box from 'src/components/BoxContainer.vue';
import { getNextActivationRoute } from 'src/services/piqSetupService';
import { getCanaryHeightWeight, updateCanaryHeightWeight } from 'src/api/endpoints/patientWeb';
import { type CanaryPatientHeightWeight } from 'src/types/webContracts';
import { useRouter } from 'vue-router';
import { QForm } from 'quasar';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const router = useRouter();

const localForm = ref(null);

const isFormValid = ref(true);
const props = defineProps({
  contentBoxWidth: {
    type: String,
    default: () => BoxWidthEnum.xs,
  },
});

const heightFt = [2, 3, 4, 5, 6, 7].map((item) => ({ text: item, value: item.toString() }));
const heightIn = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) => ({
  text: item,
  value: item.toString(),
}));

const selectedWeightLbs = ref<string | null>(null), selectedHeightFt = ref<string | null>(null), selectedHeightIn = ref<string | null>(null);

onMounted(async () => {
  $q.loading.show();

  (document.querySelectorAll('input:first-child')[0] as HTMLElement).focus();
  await getHeightWeight();

  $q.loading.hide();
});

async function getHeightWeight() {
  $q.loading.show();

  await getCanaryHeightWeight().then((response) => {
    const result: CanaryPatientHeightWeight = response?.data?.result;
    parseCanaryPatientHeightWeight(result);
  }).finally(() => {
    $q.loading.hide();
  });
}

function parseCanaryPatientHeightWeight(weightHeight: CanaryPatientHeightWeight) {
  // Weight
  const weightGr = weightHeight?.weight ?? 0;
  selectedWeightLbs.value = weightGr > 0 ? Math.floor(weightGr / 453.5924).toString() : null;

  // Height
  const totalHeightMM = weightHeight?.height ?? 0;
  if (totalHeightMM > 0) {
    const totalHeightInches = Math.floor(totalHeightMM / 25.4); //convert from mm to inches
    selectedHeightFt.value = Math.floor(totalHeightInches / 12).toString();
    selectedHeightIn.value = (totalHeightInches % 12).toString();
  } else {
    selectedHeightFt.value = null;
    selectedHeightIn.value = null;
  }
}

function getCanaryPatientHeightWeight(): CanaryPatientHeightWeight {
  const weightLbs = !selectedWeightLbs.value ? 0 : +selectedWeightLbs.value;
  const heightFt = !selectedHeightFt.value ? 0 : +selectedHeightFt.value;
  const heightIn = !selectedHeightIn.value ? 0 : +selectedHeightIn.value;

  return {
    weight: weightLbs * 453.5924, //pounds to grams
    height: (heightFt * 12 + heightIn) * 25.4, //inches to millimeters
  };
}

async function onNextClick() {

  var heightWeight = getCanaryPatientHeightWeight();
  if (!heightWeight || !await localForm.value?.validate()) return;

  $q.loading.show();
  const response = await handleApiRequest(() => updateCanaryHeightWeight(heightWeight), null, $q).finally(() => {
    $q.loading.hide();
  });

  if (!response?.result) {
  } else {
    var nextRoute = await getNextActivationRoute($q);
    router.push({ name: nextRoute }).catch(() => { });
  }
}

async function onBackClick() {
  router.go(-1);
}
</script>