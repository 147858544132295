<template>
  <q-page padding>
    <MultiViewLayout :boxWidth="$zb.enums.BoxWidthEnum.xl">
      <!-- Title -->
      <template v-slot:title>
        {{ $t('piq_bs_setup') }}
      </template>

      <!-- Heading -->
      <template v-slot:heading>
        {{ $t('piq_bs_pre_check') }}
      </template>

      <!-- Main Content -->
      <template v-slot:default>
        <div class="row document has-checkbox">
          <div class="col">
            <p>{{ $t('piq_bs_pre_check_intro') }}</p>
            <ul>

              <li>
                <div class="row no-gutters" style="margin: 20px 0">
                  <div class="col-8">{{ $t('piq_bs_pre_check_bullet_one') }}</div>
                  <div class="col-1"></div>
                  <div class="col-3">
                    <q-option-group v-model="listCheckboxes.one"
                                    color="secondary"
                                    inline
                                    name="box-one"
                                    :options="options" />
                  </div>
                </div>
              </li>

              <li>
                <div class="row no-gutters" style="margin: 20px 0">
                  <div class="col-8">{{ $t('piq_bs_pre_check_bullet_two') }}</div>
                  <div class="col-1"></div>
                  <div class="col-3">
                    <q-option-group v-model="listCheckboxes.two"
                                    color="secondary"
                                    inline
                                    name="box-two"
                                    :options="options" />
                  </div>
                </div>
              </li>

              <li>
                <div class="row no-gutters" style="margin: 20px 0">
                  <div class="col-8">{{ $t('piq_bs_pre_check_bullet_three') }}</div>
                  <div class="col-1"></div>
                  <div class="col-3">
                    <q-option-group v-model="listCheckboxes.three"
                                    color="secondary"
                                    inline
                                    name="box-three"
                                    :options="options" />
                  </div>
                </div>
              </li>

              <li>
                <div class="row no-gutters" style="margin: 20px 0">
                  <div class="col-8">{{ $t('piq_bs_pre_check_bullet_four') }}</div>
                  <div class="col-1"></div>
                  <div class="col-3">
                    <q-option-group v-model="listCheckboxes.four"
                                    color="secondary"
                                    inline
                                    name="box-four"
                                    :options="options" />
                  </div>
                </div>
              </li>

              <li>
                <div class="row no-gutters" style="margin: 20px 0">
                  <div class="col-8">{{ $t('piq_bs_pre_check_bullet_five') }}</div>
                  <div class="col-1"></div>
                  <div class="col-3">
                    <q-option-group v-model="listCheckboxes.five"
                                    color="secondary"
                                    inline
                                    name="box-five"
                                    :options="options" />
                  </div>
                </div>
              </li>

              <li>
                <div class="row no-gutters" style="margin: 20px 0">
                  <div class="col-8">{{ $t('piq_bs_pre_check_bullet_six') }}</div>
                  <div class="col-1"></div>
                  <div class="col-3">
                    <q-option-group v-model="listCheckboxes.six"
                                    color="secondary"
                                    inline
                                    name="box-six"
                                    :options="options"  />
                  </div>
                </div>
              </li>
            </ul>
            <p>{{ $t('piq_bs_pre_check_bullet_seven') }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col q-py-md">
            <!-- Checkbox -->
            <zbm-checkbox v-if="allItemsChecked" v-model="checked" @change="onCheckedClicked"
              :label="$t('piq_bs_pre_check_checkbox')" />
          </div>
        </div>

      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { defineProps, reactive, ref, watch } from 'vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import { createDecisionModal } from 'src/utils/generalPurposeModalFactory';
import { useDashboardStore, useGeneralPurposeModalStore } from 'src/store';
import { updateCanaryCompleteBaseStationPrecheck } from 'src/api/endpoints/patientWeb';
import _ from 'lodash';
import { TaskType } from 'src/constants/enums';
import { i18n } from 'src/plugins/i18n';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const router = useRouter();
const generalPurposeModalStore = useGeneralPurposeModalStore()
const dashboardStore = useDashboardStore()
const $q = useQuasar()

const $t = i18n.global.t;

const props = defineProps({
  itemId: {
    type: Number,
    required: true,
  }
})

const options = [
  { label: $t('piq_bs_pre_check_yes'), value: true },
  { label: $t('piq_bs_pre_check_no'), value: false }
];

const checked = ref(false);
const allItemsChecked = ref(false);
const listCheckboxes = reactive({
  one: null,
  two: null,
  three: null,
  four: null,
  five: null,
  six: null
});

function onRadioChanged(nV: boolean) {
  if (nV == false) {
    const info = createDecisionModal($t('action_required'), $t('piq_bs_pre_check_popup'));
    generalPurposeModalStore.setModal(info);
  }
}

async function onCheckedClicked() {
  if (checked.value) {
    $q.loading.show();
    await updateCanaryCompleteBaseStationPrecheck().then(() => {
      dashboardStore.markTaskCompleted({ id: props.itemId, taskType: TaskType.piq });
      router.push({
        name: 'Dashboard'
      });
    }).finally(() => {
      $q.loading.hide();
    })
  }
}

function onAnyChanged(nV: any) {
  let nullCount = 6;
  _.each(nV, function (i) {
    if (i != null) nullCount--;
  });

  if (nullCount == 0) {
    allItemsChecked.value = true;
  }
}

watch(() => listCheckboxes.one, (newVal) => onRadioChanged(newVal));
watch(() => listCheckboxes.five, (newVal) => onRadioChanged(newVal));
watch(listCheckboxes, (newVal) => onAnyChanged(newVal), { deep: true });
watch(checked, onCheckedClicked);

</script>